import axios from "axios";
import React, { createContext, useState, useEffect } from "react";

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(
    () => localStorage.getItem("appLanguage") || "fr"
  );
  const [dir, setDir] = useState("rtl");
  const [translations, setTranslations] = useState({});

  useEffect(() => {
    const loadTranslations = async () => {
      try {
        const response = await fetch(`/locales/${language}.json`);
        const data = await response.json();
        setTranslations(data);
      } catch (error) {
        console.error("Error loading translations:", error);
      }
    };

    setDir(language === "ar" ? "rtl" : "ltr");

    loadTranslations();

    localStorage.setItem("appLanguage", language);
  }, [language]);

  const changeLanguage = async (lang) => {
    setLanguage(lang);
    window.location.reload();
  };

  const lang = (key) => {
    const translation = translations[key];
    if (Array.isArray(translation)) {
      return translation;
    }
    return translation || key;
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage, lang, dir }}>
      {children}
    </LanguageContext.Provider>
  );
};
