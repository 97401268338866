import { useContext } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../context/LanguageContext";

const addressList = [
  {
    iconName: "icofont-google-map",
    text: "Technopark Tanger, Maroc.",
  },
  {
    iconName: "icofont-phone",
    text: "+212 659694741",
  },
  {
    iconName: "icofont-envelope",
    text: " contact@mohajir.ma",
  },
];

const socialList = [
  {
    iconName: "icofont-facebook",
    siteLink: "https://www.facebook.com/profile.php?id=61560449296768",
    className: "facebook",
  },
  {
    iconName: "icofont-twitter",
    siteLink: "#",
    className: "twitter",
  },
  {
    iconName: "icofont-linkedin",
    siteLink: "#",
    className: "linkedin",
  },
  {
    iconName: "icofont-instagram",
    siteLink: "https://www.instagram.com/contactmohajir/",
    className: "instagram",
  },
];

const FooterThree = () => {
  const { lang } = useContext(LanguageContext);
  return (
    <footer className="style-2">
      <div className="footer-top dark-view padding-tb">
        <div className="container">
          <div className="row g-4  row-cols-md-3 row-cols-1 justify-content-center">
            <div className="col">
              <div className="footer-item our-address">
                <div className="footer-inner">
                  <div className="footer-content">
                    <div className="text-center mb-3">
                      <img
                        src="/assets/images/logo/logo_footer.png"
                        style={{ height: "200px" }}
                        alt="logo footer"
                      />
                    </div>
                    <div className="content">
                      <p style={{ textAlign: "center" }}>
                        {lang("footer_desc")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="footer-item">
                <div className="footer-inner">
                  <div className=" text-center">
                    <div className="title">
                      <h4>{lang("liens_utiles")}</h4>
                    </div>
                    <div className="content">
                      <ul className="lab-ul fw-bold">
                        <li>
                          <Link to="/?scroll=about">{lang("about")}</Link>
                        </li>
                        <li>
                          <a
                            href={process.env.REACT_APP_URL_PROVIDER}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {lang("become an expert")}
                          </a>
                        </li>
                        <li>
                          <Link to="/?scroll=services">
                            {lang("our services")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/?scroll=features">
                            {lang("our strengths")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/?scroll=nos_experts">
                            {lang("nos_experts")}
                          </Link>
                        </li>
                        <li>
                          <Link to="/?scroll=comment_reserver">
                            {lang("commentReserver")}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="footer-item">
                <div className="footer-inner">
                  <div className=" text-center">
                    <div className="title">
                      <h4>{lang("InformationsDeContact")}</h4>
                    </div>
                    <div className="content">
                      <ul className="lab-ul office-address">
                        {addressList.map((val, i) => (
                          <li key={i} className="my-1">
                            <i className={val.iconName}></i>
                            {val.text}
                          </li>
                        ))}
                      </ul>
                      <ul className="lab-ul social-icons mt-3 justify-content-center">
                        {socialList.map((val, i) => (
                          <li key={i}>
                            <a
                              href={val.siteLink}
                              target="_blank"
                              className={val.className}
                            >
                              <i className={val.iconName}></i>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-3">
        <div className="container">
          <div className="section-wrapper">
            <p className="text-center m-0">
              &copy; 2024{" "}
              <Link to="/" className="fw-bold mx-1">
                Mohajir
              </Link>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterThree;
